import React from "react";
import { Link, graphql } from "gatsby"
import { connect } from 'react-redux';

class MobileIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            lang: this.props.lang,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            lang: nextProps.lang
        })
    }
    render() {
        if (this.props.location.search) {
            var url = this.props.location.search.substring(1) //get rid of "?" in querystring
            var qArray = url.split('&') //get key-value pairs
            var params = {}
            for (var i = 0; i < qArray.length; i++) {
                var pArr = qArray[i].split('=') //split key and value
                params[pArr[0]] = pArr[1]
            }
        }
        const { edges: posts } = this.state.data.allMarkdownRemark;
        return (
            <div className="mobile-index" style={this.props.transition && this.props.transition.style}>
                {posts
                    .filter(post => post.node.frontmatter.title.length > 0)
                    .map(({ node: post }) => {
                       
                            if (post.frontmatter.lang === this.state.lang) {
                                if (params && params.grupa === 2) {
                                    if (post.frontmatter.module === 'basic' || post.frontmatter.module === 'field_manager' || post.frontmatter.module === 'notes') {
                                        return (
                                            <div className="mobile-index-li" key={post.id}>
                                                <Link to={post.frontmatter.path.slice(0, post.frontmatter.path.lastIndexOf('/')) + this.props.location.search}>
                                                    <img src={require("./images/" + post.frontmatter.img + ".png")} alt="post.frontmatter.img" />
                                                    <h2>{post.frontmatter.title}</h2>
                                                </Link>
                                            </div>
                                        );
                                    }
                                } else if (this.props.location && this.props.location.state && this.props.location.state.field_manager) {
                                    if (post.frontmatter.module !== 'financial_services' && post.frontmatter.module !== 'field_manager') {
                                        return (
                                            <div className="mobile-index-li" key={post.id}>
                                                <Link to={post.frontmatter.path.slice(0, post.frontmatter.path.lastIndexOf('/')) + this.props.location.search}>
                                                    <img src={require("./images/" + post.frontmatter.img + ".png")} alt="post.frontmatter.img" />
                                                    <h2>{post.frontmatter.title}</h2>
                                                </Link>
                                            </div>
                                        );
                                    }
                                } else {
                                    if (post.frontmatter.module !== 'field_manager') {
                                        return (
                                            <div className="mobile-index-li" key={post.id}>
                                                <Link to={post.frontmatter.path.slice(0, post.frontmatter.path.lastIndexOf('/')) + this.props.location.search}>
                                                    <img src={require("./images/" + post.frontmatter.img + ".png")} alt="post.frontmatter.img" />
                                                    <h2>{post.frontmatter.title}</h2>
                                                </Link>
                                            </div>
                                        );
                                    }
                                }

                            }
                        
                        return null;
                    })}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.appReducer.lang
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileIndex);

export const pageQuery = graphql`
  query IndexQueryMobile {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___priority] },
                    filter: { frontmatter:  { type: { eq:"mobile"}}}) {
      edges {
        node {
          excerpt(pruneLength: 50)
          id
          frontmatter {
            title
            path
            lang
            type
            priority
            img
            module
          }
        }
      }
    }
  }
`;
